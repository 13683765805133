function ProjectCard(props) {
  return (
    <article
      className="Portfolio-card"
      onClick={() => props.handleClick(props.element)}
    >
      {props.element.image && (
        <img
          className="Portfolio-card-image"
          src={props.element.image}
          alt={props.element.title}
        />
      )}
      <div className="Portfolio-card-info">
        <h3 className="Portfolio-card-info-title">{props.element.title}</h3>
        <p className="Portfolio-card-info-date">{props.element.date}</p>
        <div className="Portfolio-card-info-icons">
          {props.element.technologies.map((technology, index) => (
            <img
              className="Portfolio-card-info-icons-element"
              key={index}
              src={`./assets/${technology}.svg`}
              alt={technology}
            />
          ))}
        </div>
        <button className="Portfolio-card-info-button">Explorar</button>
      </div>
    </article>
  );
}

export default ProjectCard;
