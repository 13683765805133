import { useState } from "react";
import Projects from "./projects.json";
import ProjectCard from "./components/project-card";
import Modal from "./components/modal";

function App() {
  const [activeProject, setActiveProject] = useState("");
  const [modalActive, setModalActive] = useState(false);

  const activateProject = (project) => {
    setActiveProject(project);
    toogleModal();
  };

  const toogleModal = () => {
    setModalActive(!modalActive);
  };

  return (
    <>
      {Projects.map((project, index) => (
        <ProjectCard
          element={project}
          key={index}
          handleClick={activateProject}
        />
      ))}
      {modalActive && (
        <Modal element={activeProject} handleClick={toogleModal} />
      )}
    </>
  );
}

export default App;
