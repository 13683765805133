function Modal(props) {
  return (
    <section className="Portfolio-modal">
      <div className="Portfolio-modal-container">
        <p className="Portfolio-modal-subtext">Proyecto</p>
        <h3 className="Portfolio-modal-title">{props.element.title}</h3>
        <p className="Portfolio-modal-date">{props.element.date}</p>
        {props.element.client && (
          <p className="Portfolio-modal-client">
            <span>Cliente:</span> {props.element.client}
          </p>
        )}
        {props.element.gallery ? (
          <div className="Portfolio-modal-gallery">
            <div
              className="Portfolio-modal-gallery-container"
              id="portfolioGallery"
              data-position="1"
            >
              {props.element.gallery.map((image, index) => (
                <img
                  className="Portfolio-modal-gallery-image"
                  key={index}
                  src={image}
                  alt={`Imagen ${index + 1} del proyecto ${
                    props.element.title
                  }`}
                />
              ))}
            </div>
            <div className="Portfolio-modal-gallery-navigation">
              <button
                onClick={() => window["moveGallery"](false, "portfolioGallery")}
              >
                <i className="fa-regular fa-circle-left"></i>
              </button>
              <button
                onClick={() => window["moveGallery"](true, "portfolioGallery")}
              >
                <i className="fa-regular fa-circle-right"></i>
              </button>
            </div>
          </div>
        ) : (
          <img
            className="Portfolio-modal-image"
            src={props.element.image}
            alt={props.element.title}
          />
        )}
        <p className="Portfolio-modal-description">
          {props.element.description}
        </p>
        <h5 className="Portfolio-modal-subtitle">Tecnologías:</h5>
        <div className="Portfolio-modal-icons">
          {props.element.technologies.map((technology, index) => (
            <div className="Portfolio-modal-icons-element" key={index}>
              <img
                className="Portfolio-modal-icons-element-image"
                src={`./assets/${technology}.svg`}
                alt={technology}
              />
              <p className="Portfolio-modal-icons-element-title">
                {technology}
              </p>
            </div>
          ))}
        </div>
        <div className="Portfolio-modal-links">
          {props.element.url && (
            <a
              className="Portfolio-modal-link"
              href={props.element.url}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-solid fa-globe"></i>
              <p className="Portfolio-modal-link-text">Ver proyecto</p>
            </a>
          )}
          {props.element.repository && (
            <a
              className="Portfolio-modal-link"
              href={props.element.repository}
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-github"></i>
              <p className="Portfolio-modal-link-text">Ver código</p>
            </a>
          )}
        </div>
        <button className="Portfolio-modal-close" onClick={props.handleClick}>
          <i className="fa-regular fa-circle-xmark"></i>
        </button>
      </div>
    </section>
  );
}

export default Modal;
